body {
  margin: 0;
}

code {
  font-family: SourceCodePro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Cascadia Mono-SemiLight';
  src: local('Cascadia Mono-SemiLight'), url(./fonts/CascadiaMono-SemiLight.woff2) format('woff2');
}

@font-face {
  font-family: 'Cascadia Code-SemiLight';
  src: local('Cascadia Code-SemiLight'), url(./fonts/CascadiaCode-SemiLight.woff2) format('woff2');
}

@font-face {
  font-family: 'Dank Mono';
  src: local('Dank Mono'), url(./fonts/DankMono-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Fira Code';
  src: local('Fira Code'), url(./fonts/FiraCode-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'JetBrains Mono';
  src: local('JetBrains Mono'), url(./fonts/JetBrainsMono-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'OpenDyslexic';
  src: local('OpenDyslexic'), url(./fonts/OpenDyslexic-Regular.woff) format('woff');
}

@font-face {
  font-family: 'OpenDyslexic Mono';
  src: local('OpenDyslexic Mono'), url(./fonts/OpenDyslexicMono-Regular.woff) format('woff');
}

@font-face {
  font-family: 'SourceCodePro';
  src: local('SourceCodePro'), url(./fonts/SourceCodePro-Regular.woff2) format('woff2');
}
