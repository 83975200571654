.App {
  text-align: center;
  background-color: #4c5058;
}

.RealApp {
  text-align: center;
  background-color: #040405;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

header {
  text-align: left;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

.RealApp-header {
  background-color: #ff0000;
  font-size: calc(10px + 2vmin);
  color: white;
}

.stats2, h3 {
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.rust {
  color: #ff8800;
}

#runscript:link {
  color: #ff8800;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.try2 {
  /* center everything in this div without display flex*/
  display: flex;
  justify-content: center;
  vertical-align: middle;
}

h1, h2, p {
  display: inline;
  padding: 0 5px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

html {
  --code-font: "Cascadia Mono-SemiLight",Menlo,Monaco,Consolas,monospace;
  --body-font: "Segoe UI Web (West European)","Segoe UI",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",sans-serif;
  color: #ffffff;
  background-color: #040405;
}
